<template>
  <base-page-layout title="Mercapromo">
    <div class="mb-2 mr-4">
      <header-filter
        v-model="filters"
        :filters-to-show="['campaignDates', 'actionDates', 'actions', 'status', 'offerTypes', 'industry']"
        :type="ENUM_MODALITY.MERCAPROMO"
      />
    </div>

    <div class="d-flex justify-end align-center mb-4">
      <dag-menu @clickOnInsert="openInsertDag" @clickOnEdit="openEditDag" @clickOnDelete="openDeleteDag" />
      <mf-button color="primary" label="Cadastro" icon="add" @click="create" />
    </div>

    <mf-card-container v-model="search" placeholder="Busca" use-search title="Campanhas Mercapromo">
      <template>
        <div class="selection-buttons">
          <mass-selection-button
            :show-mass-selection="showMassSelection"
            :loading="loading"
            @clickOnEdit="editMassSelection"
            @clickOnReprocess="reprocessMassSelection"
            @clickOnDelete="deleteMassSelection"
            @cancel="closeMassSelection"
          />
          <select-offers-to-edit
            v-if="isEditButtonVisible"
            :label="actionButtonLabel"
            :loading="loading"
            :disabled="hasNoOffersSelected"
            :offer-groups="groupOffers(ENUM_MODALITY.MERCAPROMO)"
            @selectOfferGroup="offerGroup => editSelectedOffers(ENUM_MODALITY.MERCAPROMO, offerGroup)"
          />
          <reprocess-offers-button
            v-if="isReprocessButtonVisible"
            :label="actionButtonLabel"
            :loading="loading"
            :disabled="hasNoOffersSelected"
            @click="reprocessSelectedOffers(ENUM_MODALITY.MERCAPROMO)"
          />
          <delete-offers-button
            v-if="isDeleteButtonVisible"
            :label="actionButtonLabel"
            :loading="loading"
            :disabled="hasNoOffersSelected"
            @click="deleteSelectedOffers(ENUM_MODALITY.MERCAPROMO)"
          />
        </div>
      </template>

      <template v-slot:headers>
        <div class="d-flex align-center" style="gap: 8px">
          <v-btn icon color="primary" @click="refreshTable"><v-icon>refresh</v-icon></v-btn>
          <export-industry-offers-data
            class="mr-3"
            :modality="ENUM_MODALITY.MERCAPROMO"
            :filters="filters"
            :search="search"
            :industry-ids="selectedIndustryIds"
          />
        </div>
      </template>

      <scrollable-table
        v-model="selectedOffers"
        class="mt-4"
        :loading="$apollo.queries.offers.loading"
        :headers="headers"
        :items="offers"
        :options.sync="options"
        :server-items-length="dataCount"
        :show-select="showMassSelection"
        item-key="_id"
        :selectable-key="canBeSelectedKey"
      >
        <template v-slot:item.name="{ item }" class="truncate-name">
          <tooltip align="top" fit-content :message="item.name">
            {{ item.formattedTitle | truncate({ length: 62 }) }}
          </tooltip>
        </template>

        <template #item.action="{ item }">
          <span>{{ item.updated_at | date('dd/MM/yyyy | HH:mm') }}</span>
        </template>

        <template v-slot:item.status="{ item }">
          <status-in-table :action="item.action" :status="item.status" />
        </template>

        <template v-slot:item.start_date="{ item }">
          {{ item.start_date | date('dd/MM/yyyy') }}
        </template>

        <template v-slot:item.end_date="{ item }">
          {{ item.end_date | date('dd/MM/yyyy') }}
        </template>

        <template v-slot:item.proposal_deadline="{ item }">
          {{ item.proposal_deadline | date('dd/MM/yyyy') }} {{ item.proposal_deadline ? '| 23:59' : '' }}
        </template>

        <template v-slot:item.industry.name="{ item }">
          {{ item.industry.name }}
        </template>

        <template v-slot:item.offer_type="{ item }" class="truncate-name">
          {{ item.offer_type }}
        </template>

        <template v-slot:item.actions="{ item }">
          <context-menu
            :item="item"
            :modality="ENUM_MODALITY.MERCAPROMO"
            @edit="handleSingleEdit(ENUM_MODALITY.MERCAPROMO, item)"
            @reprocess="handleSingleReprocess(ENUM_MODALITY.MERCAPROMO, item)"
            @delete="handleSingleDelete(ENUM_MODALITY.MERCAPROMO, item)"
          />
        </template>
      </scrollable-table>
    </mf-card-container>
  </base-page-layout>
</template>

<script>
import { QUERY_GET_MERCAPROMO_OFFERS_PAGINATED } from '@/modules/industry/graphql'
import { manageIndustryOffers } from '@/modules/industry/mixins/manage-industry-offers'

export default {
  components: {
    Tooltip: () => import('@/components/base/Tooltip.vue'),
    DagMenu: () => import('@/modules/industry/components/DagMenu')
  },
  mixins: [manageIndustryOffers],
  data: () => ({
    loadedFilters: false,
    filters: {
      dates: [],
      actionDates: [],
      actions: [],
      status: [],
      offerTypes: [],
      industries: []
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Campanha',
          value: 'name',
          sortable: true,
          filterable: true,
          width: '20%'
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          width: '130px'
        },
        {
          text: 'DB Name',
          value: 'db_name',
          sortable: true,
          filterable: true
        },
        {
          text: 'ID Varejista',
          value: 'id_external',
          sortable: false,
          filterable: false
        },
        {
          text: 'Data início',
          value: 'start_date',
          sortable: false
        },
        {
          text: 'Data fim',
          value: 'end_date',
          sortable: false
        },
        {
          text: 'Prazo de adesão',
          value: 'proposal_deadline',
          sortable: false
        },
        {
          text: 'Indústria',
          value: 'industry.name',
          sortable: true
        },
        {
          text: 'Tipo de Oferta',
          value: 'offer_type',
          sortable: true
        },
        {
          text: 'Última Ação',
          value: 'action',
          sortable: false,
          width: '130px'
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '4%'
        }
      ]
    }
  },
  apollo: {
    offers: {
      skip() {
        return !this.loadedFilters
      },
      query: QUERY_GET_MERCAPROMO_OFFERS_PAGINATED,
      fetchPolicy: 'network-only',
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['accounts']
        }
      },
      variables() {
        let input = {
          actions: this.filters.actions,
          status: this.filters.status,
          offer_types: this.filters.offerTypes,
          industry_ids: this.selectedIndustryIds
        }

        if (this.filters?.dates?.[0] && this.filters?.dates?.[1]) {
          input.startCampaignPeriod = {
            startDate: this.filters.dates[0],
            endDate: this.filters.dates[1]
          }
        }

        if (this.filters?.actionDates?.[0] && this.filters?.actionDates?.[1]) {
          input.lastUpdatePeriod = {
            startDate: this.filters.actionDates[0],
            endDate: this.filters.actionDates[1]
          }
        }

        return {
          input,
          pagination: {
            page: this.options.page || 1,
            pageSize: this.options.itemsPerPage || 50,
            search: this.debounceSearch || null,
            sort: this.options.sortBy,
            sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
          }
        }
      },
      update(result) {
        this.dataCount = result.getMercapromoOffers.count
        const data = result.getMercapromoOffers.data.map(offer => ({
          ...this.addCommonFields(offer),
          formattedTitle: this.formatCampaignTitle(offer.name)
        }))
        return data
      }
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.options.page = 1
        window.localStorage.setItem('filters-mercapromo-industry', JSON.stringify(this.filters))
      },
      deep: true
    }
  },
  beforeMount() {
    this.loadedFilters = false
    const savedFilters = window.localStorage.getItem('filters-mercapromo-industry')
    if (savedFilters) {
      const parsed = JSON.parse(savedFilters)
      this.filters.dates = parsed.dates ? parsed.dates : []
      this.filters.actionDates = parsed.actionDates ? parsed.actionDates : []
      this.filters.actions = parsed.actions ? parsed.actions : []
      this.filters.status = parsed.status ? parsed.status : []
      this.filters.offerTypes = parsed.offerTypes ? parsed.offerTypes : []
      this.filters.industries = parsed.industries ? parsed.industries : []
    }
    this.loadedFilters = true
  },
  methods: {
    create() {
      this.$router.push(`/mercapromo/create`)
    },

    formatCampaignTitle(title) {
      return title.replace(/campanhas?|mercapromo/gi, ' ').trim()
    },
    openInsertDag() {
      return window.open(process.env.VUE_APP_MERCAPROMO_INSERT, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.selection-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
  gap: 8px;
}

@media screen and (max-width: 1366px) {
  .truncate-name {
    font-size: 12px !important;
  }
}
</style>
